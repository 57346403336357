<template>
    <section class="section section--form">
        <b-container>
            <b-row>
                <b-col lg="9">
                    <div class="form-page">
                        <div class="form-header">
                            <div class="heading">đăng nhập</div>
                        </div>
                        <b-form class="form" id="login" @submit.prevent="login">
                            <div class="form__custom" :class="{ 'error': $v.formData.username.$error }">
                                <b-form-input class="form-input" placeholder="Tên đăng nhập" type="text"
                                    v-model.trim="$v.formData.username.$model"></b-form-input>
                                <p v-if="!$v.formData.username.required" class="form-note">Vui lòng nhập tên đăng nhập</p>
                                <p v-if="!$v.formData.username.usernameValidation" class="form-note">Tên đăng nhập chỉ được gồm: a-z, 0-9 và dấu gạch dưới (_).</p>
                            </div>
                            <div class="form__custom" :class="{ 'error': $v.formData.password.$error }">
                                <b-form-input class="form-input" placeholder="Vui lòng nhập mật khẩu"
                                    v-model.trim="$v.formData.password.$model"
                                    :type="isShowPassword ? 'text' : 'password'"></b-form-input>
                                <i class="las la-eye" :class="{active: isShowPassword}" @click="isShowPassword = !isShowPassword"></i>
                                <p v-if="!$v.formData.password.required" class="form-note">Vui lòng nhập mật khẩu</p>
                                <p v-if="!$v.formData.password.minLength" class="form-note">Mật khẩu phải trên 6 ký tự</p>
                            </div>
                            <div class="forgot-pass">
                                <a data-toggle="modal" href="javascript:void(0)" v-b-modal.forgotPasswordModal>Quên Mật Khẩu?</a>
                            </div>
                            <div class="form__custom error text-center">
                                <p v-if="messageLoginFail !== ''" class="form-note">{{messageLoginFail}}</p>
                            </div>
                            <d-button type="submit" class="button"
                                :disabled="isSubmit"
                                :loading="isSubmit">
                                ĐĂNG NHẬP NGAY
                            </d-button>
                            <div class="form-link flex-box">
                                Bạn chưa có tài khoản?
                                <a data-dismiss="modal" data-target="#myRegister" data-toggle="modal" href="javascript:void(0)" v-b-modal.registerModal>
                                    ĐĂNG KÝ NGAY
                                </a>
                            </div>
                        </b-form>
                        <bannerEventForm v-if="$isEnableThemeWorldcup()" id="bannerPageLogin" />
                    </div>
                </b-col>
                <b-col lg="3">
                    <div class="form-banner">
                        <n-link to="/chuong-trinh-khuyen-mai">
                            <s-img src="/assets/img/banner/form-banner.jpg" alt="khuyenmai"/>
                        </n-link>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>
<script>
// eslint-disable-next-line import/no-unresolved
import loginMixins from '@/mixins/user/login';
// eslint-disable-next-line import/no-unresolved
import bannerEventForm from '@/components/desktop/banner-event-form.vue';

export default {
    mixins: [loginMixins],
    components: {
        bannerEventForm
    }
};
</script>
