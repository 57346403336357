<template>
    <component :is="component"/>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import base from '~/components/base.vue';
// eslint-disable-next-line import/no-unresolved
import desktop from '~/components/desktop/login.vue';

export default {
    extends: base,
    middleware: 'auth',
    components: {
        desktop
    },
    head() {
        return {};
    },
    data() {
        return {
        };
    },
    methods: {
    },
    mounted() {
    }
};

</script>
